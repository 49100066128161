<template>
  <div class="outer sub" id="wrapper">
    <div class="container-fluid">
      <div class="row padding260">
        <div class="col-md-1 col-lg-1">
        </div>
        <div class="col-md-4 col-lg-4">
          <h2>{{ $t("book.title") }}</h2>
          <p>{{ $t("book.content1") }}</p>
          <br/>
          <p>{{ $t("book.content2") }}</p>
          <br/>
          <p>{{ $t("book.content3") }}</p>
          <br/>
          <p>{{ $t("book.content4") }}</p>
        </div>
        <div class="col-md-1 col-lg-1">
        </div>
        <div class="col-md-4 col-lg-4">
          <!-- <iframe TableFever  data-tablefever-widget src="https://widget.tablefever.com/src/widget-v-4/app/dist/index.html"></iframe> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: "BodyBook",
  // mounted() {
  // }
};
</script>
